import { map } from "jquery";
import {
    DASHBOARDS, FormatTypes,
    PROFILE_COLUMN,
    PS_MAPPING,
    PSL_RETURN_NAMES,
    SEGMENTS_TITLES,
    VECTOR_SEGMENTS_TABS_OPTIONS
} from "./constants";
import { copyObjectValues } from "./utils";
const _type = PROFILE_COLUMN.TYPE;
const _children = PS_MAPPING.FIELDS.CHILDREN;

/**
 * This function takes an array of objects and a key, and returns 
 * the index of the object having the lowest value for this key,
 * @param {*} data 
 * @param {*} key 
 * @param {*} exceptIndices -- indices to skip checking
 */
function getLowestKey(data, key, exceptIndices=[]) {
    let lowestIndex = -1;
    let min = 0;
    data.forEach((row, i)=> {
        if(exceptIndices.includes(i)) return;
        row[key] = parseFloat(row[key]);

        if(lowestIndex === -1) {   //initialisation with the value of the first row
            lowestIndex = i;
            min = row[key];
        }

        if(row[key] < min) {
            min = row[key];
            lowestIndex = i;
        }
    });

	return lowestIndex;
}

/**
 * This function takes an array of objects and a key, and returns 
 * the index of the object having the highest value for this key
 * @param {*} data 
 * @param {*} key 
 * @param {*} exceptIndices -- indices to skip checking
 */
function getHighestKey(data, key, exceptIndices=[],type) {
    let highestIndex = -1;
    let max = 0;
    data.forEach((row, i)=> {
        if(exceptIndices.includes(i)) return;
        row[key] = parseFloat(row[key]);

        if(highestIndex === -1) {   //initialisation with the value of the first row
            if (type && type.includes(row[_type])) {
                highestIndex = i;
                max = row[key];
            }else if (!type) {
                highestIndex = i;
                max = row[key];
            }
        }

        if(row[key] > max) {
            if (type && type.includes(row[_type])) {
                max = row[key];
                highestIndex = i;
            }else if (!type) {
                max = row[key];
                highestIndex = i;
            }
        }
    });

	return highestIndex;
}

function findIndexOfValue(data, key, value) {
    if(!data || !Array.isArray(data)) {
        return;
    }
    let index = -1;
    key = key || "value";
    data.forEach((row, i)=>{if(row[key] === value && index === -1 && !row.new) {index = i; return;}});

    return index;
}

function moveIndex(data, currentIndex, newIndex) {
    let item = data.splice(currentIndex, 1);    //remove item from its current index
    item = item[0] ? item[0] : item;
    data.splice(newIndex, 0, item);

    return data;
}

function hasEmptyValues(array) {
    if(!Array.isArray) {
        return;
    }

    return array.includes("") || array.includes(undefined) || array.includes(null);
}

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}

function deleteEmptyIndices(array) {
    let arrayRange = range(array.length);
    let returnedArray = [];
    for (var i in arrayRange) {
        if(!!array[i]) {
            returnedArray.push(array[i]);
        }
    }

    return returnedArray;
}

/**
 * This function returns a child that has value "value" for "key"
 * @param {*} data 
 * @param {*} childrenKey   -- key under which children are saved
 * @param {*} key 
 * @param {*} value 
 */
function getEmbeddedChild(data, childrenKey, key, value) {
    var retChild = null;
    data.forEach(child => {
        if(retChild) {
            return false;   //if child already found, do not reassign to null
        }
        if(child[key] === value) {
            retChild = child;
        } else if(child[childrenKey]) {
            retChild = getEmbeddedChild(child[childrenKey], childrenKey, key, value);
        }
    });

    return retChild;
}

/**
 * This function returns a child that has value "value" for "key"
 * @param {*} data 
 * @param {*} childrenKey   -- key under which children are saved
 * @param {*} key 
 * @param {*} value 
 */
function getEmbeddedChildren(data, childrenKey, key, value, operator) {
    var retChildren = [];
    data.forEach(child => {
        // if(retChildren) {
        //     return false;   //if child already found, do not reassign to null
        // }
        if (operator === "<") {
            if(child[key] !== undefined && (typeof value !== "boolean" && parseInt(child[key]) < value)) {
                retChildren.push(child);
            }
        }else{
            if(child[key] !== undefined && (typeof value !== "boolean" && value.includes(child[key]) || value  === (child[key]))) {
                retChildren.push(child);
            }
        }
        
        if(child[childrenKey]) {
            retChildren = retChildren.concat(getEmbeddedChildren(child[childrenKey], childrenKey, key, value, operator));
        }
    });

    return retChildren;
}

/**
 * this function is a substitute for Object.values() because it is not supported in IE
 * @param {*} obj 
 */
function getObjectValues(obj) {
	return Object.keys(obj).map(function(item) {
		return obj[item];
	});
}

/**
 * this function take the index of a row and the tabulator page size, and it returns at which page this row is
 * 
 */
 function getTabulatorPagePosition(index, pageSize) {
	return Math.floor(1+ Number(index)/Number(pageSize));
}

/**
 * this function combines the elements of two arrays based on a certain key's value and an evaluated expression
 * @param {*} firstObj      -- two objects of the form: {identifier: <certainId>, array: <certainArray>}
 * @param {*} secondObj 
 * @param {*} key           -- key whose value determines whether or not an item is present in both arrays
 * @param {*} expression    -- could be any string representation of a function, ex: ".toLowerCase()"
 */
function combineOverKey(firstObj, secondObj, key, expression) {
    let id1 = firstObj.identifier;
    let id2 = secondObj.identifier;

    let array1 = firstObj.array;
    let array2 = secondObj.array;

    if(!Array.isArray(array1) || !Array.isArray(array2)) {
        return null;
    }

    expression = expression || "";
    let combinedArray = [];
    array1.forEach(item=>{
        let secondItem = array2.filter(secIt => eval(("\""+secIt[key]+"\"") + expression) === eval(("\""+item[key]+"\"") + expression))[0];

        if(!!secondItem) {
            secondItem.expired = true;      //set this item in second array as expired to exclude from loop over second array
            item.isIn = [id1, id2];         //set item as available in both arrays
        } else {
            item.isIn = [id1];              //set item as available in the first array only
        }
        combinedArray.push(item);
    });

    array2.filter(item=>!item.expired).forEach(item=>{
        item.isIn = [id2];              //set item as available in the second array only
        combinedArray.push(item);
    });

    return combinedArray;
}

/**
 * this function takes an array of embedded data
 * and returns it as an array of linear data
 * @param {*} data 
 * @param {*} childrenKey   //they key under which the children are stored in each object
 */
function linearizeHierarchy(data, childrenKey, addChildrenFlag) {
    let linearArr = [];

    for(let element in data) {
        element = data[element];
        let tempCopy = copyObjectValues(element);
        if(addChildrenFlag && element[childrenKey] && element[childrenKey].length>0){
            tempCopy.hasChildren = true;
        }
        delete tempCopy[childrenKey];       //deleting the children from the element in the linear array
        linearArr.push(tempCopy);

        if(element[childrenKey] || element.children) {
            linearArr = linearArr.concat(linearizeHierarchy(element[childrenKey] || element.children, childrenKey, addChildrenFlag));
        }
    }

    return linearArr;
}

  /**
* this function is to check if a ps line is under a line or under this line's childrens
*/
function checkLineUnderParent(dataRow, value, key, value2){
   dataRow = linearizeHierarchy(dataRow.filter(e=>e.returnName === value), _children);
   return dataRow.filter(e=>e[key] === value2).length>0;
}

/**
 * this function is to check if a specific line parent is expanded
 * @param {*} tabulator 
 * @param {*} value 
 * @param {*} key 
 * @returns 
 */
function checkLineExpanded(tabulator, value, key){
    let keyRow = tabulator.getRows().filter(e=>e._row.data[key] === value);
    return keyRow? keyRow[0]._row.modules.dataTree.open : false;
}
/**
 *  Delete unwanted nodes from tree 
 * @param {*} array 
 * @param {*} childrenKey 
 * @param {*} valueKey 

 */
function prune(array,childrenKey,valueKey,elementToBeRemoved) {
    for (var i = 0; i < array.length; ++i) {
        var obj = array[i];
        if (obj[valueKey].includes(elementToBeRemoved)) {
            // splice out 1 element starting at position i
            array.splice(i, 1);
           
        }
        if (obj[childrenKey]) {
            if (prune(obj[childrenKey],childrenKey,valueKey,elementToBeRemoved)) {
                if (obj[childrenKey].length === 0) {
                    // delete this parent altogether
                    // as a result of it having no more children
                    // do this instead
                    array.splice(i, 1);
                }
                
            }
        }
    }
}
/**
 * Add child object to Nested Tree By ParentId
 * @param {*} arr 
 * @param {*} idKey 
 * @param {*} id 
 * @param {*} children 
 */
function addChildByParentId(arr,idKey,id,children){
    arr.forEach(i=>{
        if(i[idKey]===id){
           i.children = [...(i.children || []), ...children];
        } else {
            addChildByParentId(i.children|| [],idKey,id, children)
        }
    })
}

/**
 * this function takes an array of embedded data, and returns an array of indices.
 * Each index being on one hierarchy level, these indices lead from the parent
 * down to the child having a the required value for the required key
 * @param {*} data 
 * @param {*} childrenKey 
 * @param {*} key 
 * @param {*} value 
 */
function getPathToEmbeddedChild(data, childrenKey, key, value) {
    let pathArrary = [];
    for(let index in data) {
        let element = data[index];

        if(element[key] === value) {    //if this is the element we're looking for, we also want its index
            pathArrary.push(index);
        }
        if(!!element[childrenKey] && element[childrenKey].length) {
            if(getEmbeddedChild(element[childrenKey], childrenKey, key, value)) {
                //if the element we're looking for, is a child of the current looped over element, add its index to the array
                pathArrary.push(index);
                //also add the indices of the children in between the main parent and the main child
                pathArrary = pathArrary.concat(getPathToEmbeddedChild(element[childrenKey], childrenKey, key, value));
            }
        }
    }

    return pathArrary;
}

/**
 * this function takes a two dimensional array and transposes it
 * --> turns rows into columns and columns into rows
 * @param {*} twoDimensionalArray 
 */
function transpose(twoDimensionalArray) {
    return twoDimensionalArray[0].map((unusedValue, colIndex) => twoDimensionalArray.map(row => row[colIndex]));
}

/**
 * this function loops over all the data and for each element
 * sets the specified value as value for the specified key
 * @param {*} data 
 * @param {*} childrenKey 
 * @param {*} key 
 * @param {*} value 
 */
function updateAllElements(data, childrenKey, key, value) {
    for(let element in data) {
        element = data[element];
        element[key] = value;

        if(!!element[childrenKey]) {
            updateAllElements(element[childrenKey], childrenKey, key, value);
        }
    }

    return data;
}

/**
 * recursive function to add level for each child
 * @param {*} data 
 * @param {*} childrenKey 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
function addLevelForAll(data, childrenKey, key, value) {
    for(let element in data) {
        element = data[element];
        element[key] = element[key] + value;

        if(!!element[childrenKey]) {
            addLevelForAll(element[childrenKey], childrenKey, key, value);
        }
    }

    return data;
}

function updateAllElementsTree(data, lines, mapKey, childrenKey, key, value, isEdit) {
    for(let el in data) {
        let element = data[el];
        if(lines.filter(e=>e[mapKey] === element[mapKey]).length > 0){
            element[key] = value;
            if(!!element[childrenKey] && isEdit) {
                let result = element[childrenKey].every(function (e) {
                    return lines.map(e=>e[mapKey]).includes(e[mapKey]);
                });
                if(result){ 
                    element[childrenKey] = updateAllElements(element[childrenKey], childrenKey, key, value);
                }
            } else if(!!element[childrenKey]){
                updateAllElementsTree(element[childrenKey], lines, mapKey, childrenKey, key, value,isEdit);
            }
        } else {
            if(!!element[childrenKey]) {
                updateAllElementsTree(element[childrenKey], lines, mapKey, childrenKey, key, value,isEdit);
            }
        }
    }

    return data;
}

function updateAllTableElements(tabulator, line, mapKey, childrenKey, key, value) {
    let data = tabulator.getData();
    
    for(let element in data) {
        element = data[element];
        if(line[mapKey] === element[mapKey]){
            element[key] = value;
            if(!!element[childrenKey]) {
                data[element] = tabulator.current.updateRow(element[childrenKey], line);
            }
        } else {
            if(!!element[childrenKey]) {
                updateAllElementsTree(element[childrenKey], line, mapKey, childrenKey, key, value);
            }
        }
    }

    return data;
}

function addElements(data, childrenKey, key, value) {
    for(let element in data) {
        element = data[element];
        if(typeof element !== 'string'){
            element[key] = element[value];
        }

        if(!!element[childrenKey]) {
            addElements(element[childrenKey], childrenKey, key, value);
        }
    }

    return data;
}

function addElement(data, key, value) {
    for(let element in data) {
        element = data[element];
        element[key] = value;

    }

    return data;
}

function addAttributeElement(element, key, value) {
       element[key] = value;
    return element;
}

/**
 * this function loops over all the data and for each element
 * replace the value a key with a different key
 * @param {*} data 
 * @param {*} keyOld 
 * @param {*} keyNew
 * @param {*} childrenKey
 */
 function updateElementsWithChildren(data, childrenKey, keyOld, keyNew) {
    for(let element in data) {
        element = data[element];
        element[keyNew] = element[keyOld];
        delete element[keyOld];
        if(!!element[childrenKey]) {
            if(element[DASHBOARDS.WIDGET.CATEGORY]===DASHBOARDS.WIDGET.ATTRIBUTES){
                addAttributeElement(element,"className","hideMe");
            }
            updateElementsWithChildren(element[childrenKey], childrenKey, keyOld, keyNew);
        }
    }

    return data;
}

function removeDuplicates(options) {
    if(!Array.isArray(options)) {
        return options;
    }

    let type = typeof options[0];

    let optionsSet = new Set(options.map(e => type === "object" ? JSON.stringify(e) : e));
    let uniqueOptions = Array.from(optionsSet).map(e => type === "object" ? JSON.parse(e) : e);

    return uniqueOptions;
}

/**
 * this function takes an array of objects, and returns an array containing only the first object
 * from each group of objects having the same value under this key
 * @param {*} array 
 * @param {*} key 
 */
function removeDuplicateKeyValues(array, key) {
    let addedKeys = [];
    let returnElements = array.map(elem=>{
        if(addedKeys.includes(elem[key])) {
            return undefined;
        }
        addedKeys.push(elem[key]);
        return elem;
    });
    return deleteEmptyIndices(returnElements);
}

/**
 * This function takes two arrays and checks that all elements in secondary exist in main.
 * These arrays may contain objects instead of values, in which case, the array containing objects
 * must have a key to extract the values to compare in keyMap. It returns an array containing the missing items.
 * @param {*} mainArray 
 * @param {*} secondaryArray 
 * @param {*} keyMap 
 */
function getMissingItems(mainArray, secondaryArray, keyMap) {
    let missingItems = [];
    if(secondaryArray.length === 0) {
        return [];
    }
    let isObject = typeof secondaryArray[0] === "object";
    let mainArrayToCompare = isObject && !!keyMap["main"] ? mainArray.map(en=>en[keyMap["main"]]) : mainArray;
    let secondaryArrayToCompare = isObject && !!keyMap["secondary"] ? secondaryArray.map(en=>en[keyMap["secondary"]]) : secondaryArray;

    for(let i=0; i < secondaryArrayToCompare.length; i++){
        if(!mainArrayToCompare.includes(secondaryArrayToCompare[i])) {
            missingItems.push(secondaryArray[i]);
        }
    }
    return missingItems;
}

function includesAll(mainArray, secondaryArray, keyMap) {
    return getMissingItems(mainArray, secondaryArray, keyMap).length === 0;
}

function getEmbeddedChildName(returnName, data, checkedItems) {
    for (let e in data) {
        if (data[e][PROFILE_COLUMN.RETURN_NAME] === returnName) {
            // If we find a match, we return the name
            let result = data[e][PROFILE_COLUMN.NAME];

            // If checkedItems contains vector types, format the name accordingly
            if (checkedItems?.find(f => f.column_return_name === returnName)?.type === DASHBOARDS.CONFIG_FIELD.VECTOR) {
                let vectorsOptions = checkedItems
                    .filter(f => f.column_return_name === returnName && f.machine_name !== PSL_RETURN_NAMES.COUNT_PER_SELECTION)
                    .map(m => m.machine_name?.replace(PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER, VECTOR_SEGMENTS_TABS_OPTIONS[1].label)
                        .replace(PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT, VECTOR_SEGMENTS_TABS_OPTIONS[0].label)
                        .replaceAll("_", " "));

                result = result + " (" + vectorsOptions.join(", ") + ")";
            }
            return result; // Return as soon as a match is found
        }

        // If there are children, we search recursively
        if (data[e][PROFILE_COLUMN.CHILDREN]) {
            let childName = getEmbeddedChildName(returnName, data[e][PROFILE_COLUMN.CHILDREN], checkedItems);

            // If a valid child name is found, return it
            if (childName !== returnName) {
                return childName;
            }
        }
    }
    
    // Return the original returnName if nothing is found
    return returnName;
}



export {getLowestKey, getHighestKey, findIndexOfValue, moveIndex, hasEmptyValues, range, deleteEmptyIndices, getEmbeddedChild,checkLineUnderParent,
    getObjectValues, combineOverKey, linearizeHierarchy, getPathToEmbeddedChild, transpose, updateAllElements,updateAllElementsTree, updateAllTableElements, updateElementsWithChildren,checkLineExpanded,
    removeDuplicates, removeDuplicateKeyValues, getMissingItems, addLevelForAll, includesAll, getEmbeddedChildren ,addElements,addElement, getTabulatorPagePosition,prune,addChildByParentId,
    getEmbeddedChildName}